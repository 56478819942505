body,
html {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow: hidden; /* Prevent scrolling */
}

.windowsContainer,
.standardContainer {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.imageContainer {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/windowsBg.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
}

.taskbarLanguage {
	margin-right: auto; /* Align it to the right */
	padding-left: 10px; /* Add some spacing */
}

.taskbarLanguage button {
	background-color: #007bff;
	color: white;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.3s ease;
}

.taskbarLanguage button:hover {
	background-color: #0056b3;
}

.appSidebar {
	z-index: 1;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 120px; /* Adjusted width to accommodate more items */
	height: 100vh;
	padding: 5px;
	background-color: transparent;
	margin-left: 5px;
	margin-top: 10px;
}

h1,
.appName {
	color: white;
	word-wrap: normal; /* Break-word wrap'ı kapattık */
	overflow-wrap: normal; /* Break-word wrap'ı kapattık */
	text-align: center;
	font-size: 14px; /* Tüm çözünürlüklerde sabit font boyutu */
}

.appContainer {
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 80px;
}

.adobeContainer,
.linkedinContainer,
.macosContainer,
.githubContainer,
.buyCoffeContainer,
.folderContainer,
.worksContainer,
.noteContainer {
	width: 50px;
	height: 50px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 16%;
	cursor: pointer;
}

.adobeContainer {
	background-image: url('../assets/images/adobe.png');
}

.mailContainer {
	background-image: url('../assets/images/mail.png');
}

.linkedinContainer {
	background-image: url('../assets/images/linkedin.png');
}

.githubContainer {
	background-image: url('../assets/images/github.jpg');
}

.macosContainer {
	background-image: url('../assets/images/macbook.jpg');
}

.buyCoffeContainer {
	background-image: url('../assets/images/buymeCoffe.png');
}

.folderContainer {
	background-image: url('../assets/images/folder.png');
}
.worksContainer {
	background-image: url('../assets/images/folder.png');
}
.noteContainer {
	background-image: url('../assets/images/notepad.png');
}

.taskbar {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	height: 50px;
	background-color: rgba(255, 255, 255, 0.01);
	backdrop-filter: blur(10px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3);
	z-index: 2;
	color: black;
	border-radius: 10px;
}

.taskbarCenter {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.startButton {
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-image: url('../assets/images/startButton.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 15%;
}

.taskbarIcons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.taskbarIcon,
.taskbarGlutenMetre,
.taskbarProjects,
.taskbarWorks,
.taskbarMail,
.startButton,
.taskbarNeoCrea,
.taskbarEgeOrmanVakfi,
.taskbarDeka,
.taskbarKaravan,
.taskbarUlkePuan {
	width: 30px;
	height: 30px;
	margin: 0 5px;
	border-radius: 5px;
	cursor: pointer;
}

.taskbarGlutenMetre {
	background-image: url('../assets/images/appstore.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
}
.taskbarNeoCrea {
	background-image: url('../assets/images/neocrea_logo-removebg-preview.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
	width: 32px;
	height: 32px;
}
.taskbarEgeOrmanVakfi {
	background-image: url('../assets/images/egeorman.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
}
.taskbarDeka {
	background-image: url('../assets/images/cropped-dekatechs-favicon-512x51.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
}
.taskbarKaravan {
	background-image: url('../assets/images/5klogo.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
}
.taskbarUlkePuan {
	background-image: url('../assets/images/custom-icon.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
	width: 32px;
	height: 32px;
}

.taskbarProjects {
	background-image: url('../assets/images/folder.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.taskbarMail {
	background-image: url('../assets/images/mail.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.taskbarWorks {
	background-image: url('../assets/images/folder.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.taskbarTime {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60px;
	color: #fff;
	position: absolute;
	right: 20px;
}

span {
	font-size: small;
	text-align: center;
}

@media (max-width: 1024px) {
	.appSidebar {
		width: 80px;
	}
	.adobeContainer,
	.linkedinContainer,
	.macosContainer,
	.buyCoffeContainer,
	.githubContainer,
	.folderContainer,
	.worksContainer {
		width: 50px;
		height: 50px;
	}
	.taskbarTime {
		width: 60px;
	}
}

@media (max-width: 768px) {
	.appSidebar {
		width: 60px;
	}
	.adobeContainer,
	.linkedinContainer,
	.macosContainer,
	.buyCoffeContainer,
	.githubContainer,
	.folderContainer,
	.worksContainer {
		width: 50px;
		height: 50px;
	}
	.taskbarTime {
		width: 50px;
	}
	span {
		font-size: x-small;
	}
	.backButton {
		margin-bottom: 60px;
	}
}

@media (max-width: 480px) {
	.appContainer {
		overflow-x: hidden;
	}
	.appSidebar {
		width: 50px;
	}
	.adobeContainer,
	.linkedinContainer,
	.macosContainer,
	.buyCoffeContainer,
	.githubContainer,
	.folderContainer,
	.worksContainer {
		width: 40px;
		height: 40px;
	}
	.taskbarTime {
		width: 40px;
		margin-left: 20px;
	}
	span {
		font-size: xx-small;
	}
}
@media (max-width: 400px) {
	.taskbarTime {
		opacity: 0;
	}
	span {
		font-size: xx-small;
	}
}
@media (max-width: 320px) {
	.taskbarTime {
		opacity: 0;
	}
	span {
		font-size: xx-small;
	}
	.taskbarIcon,
	.taskbarGlutenMetre,
	.taskbarProjects,
	.taskbarWorks,
	.taskbarMail,
	.startButton,
	.taskbarNeoCrea,
	.taskbarEgeOrmanVakfi,
	.taskbarDeka,
	.taskbarKaravan,
	.taskbarUlkePuan {
		width: 25px;
		height: 25px;
		margin: 0 5px;
		border-radius: 5px;
		cursor: pointer;
	}
}

/* Projects Overlay */
.projectsOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
}

.backButton {
	position: absolute;
	top: 10px;
	right: 50px;
	padding: 8px 12px;
	background-color: #ccc;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}
