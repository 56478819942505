.fullscreenModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.fullscreenImage {
	max-width: 90%;
	max-height: 90%;
	border-radius: 10px;
}

.closeButton {
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: #333;
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
