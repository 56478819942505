html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: auto; /* Make the whole page scrollable */
}

.outerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% - 15px); /* Adjust height to account for the fixed top */
	padding: 20px;
	box-sizing: border-box;
	margin-top: 15px; /* Add margin to avoid content overlap */
	overflow-y: hidden;
}

.fixedTop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 25px;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	z-index: 10; /* Ensure it stays on top */
}

.container {
	position: relative; /* Added for the close button positioning */
	border: solid;
	border-width: 1px;
	flex-direction: column;
	padding: 20px;
	background-color: #1c1b1f;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	border-color: #3f3f3f;
	width: 80%; /* Adjust width according to your preference */
	max-width: 1200px;
	max-height: calc(
		80vh - 15px
	); /* Adjust height to account for the fixed top */
	overflow: hidden; /* Hide overflowing content */
}

.main {
	flex: 1;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
}

.headerText {
	font-size: xx-large;
	font-weight: 400;
	text-align: left;
	border-bottom: 2px solid #323037;
	padding-bottom: 10px;
	margin-bottom: 20px;
	color: #fff;
}

.projectContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 20px;
}

.projectInfo {
	flex: 1;
}

.projectTitle {
	font-size: xx-large;
	font-weight: bold;
	text-align: left;
	margin: 10px 0;
	color: #fff;
}

.projectCategory {
	font-size: medium;
	font-weight: 300;
	text-align: left;
	color: lightgray;
}

.projectCategoryPointer {
	font-size: medium;
	font-weight: 300;
	text-align: left;
	color: lightgray;
	cursor: pointer;
	margin-left: 10px;
}

.projectDescription {
	font-size: medium;
	font-weight: 400;
	text-align: left;
	color: #fff;
	margin-left: 10px;
}
h3 {
	color: #fff;
}

.seeMoreButton {
	background-color: #162c53;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 12px;
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.seeMoreButton:hover {
	background-color: #1c335a;
}

.projectImageContainer {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	margin-left: 10px;
	padding: 10px;
}

.projectImage {
	max-width: 600px;
	border-radius: 10px;
	height: 300px;
}

.appImage {
	max-width: 300px;
	border-radius: 10px;
	height: 250px;
}
.logoImage {
	max-width: 300px;
	border-radius: 10px;
	height: 150px;
	margin-right: 20px;
}
.profileImage {
	max-width: 400px;
	border-radius: 10px;
	height: 200px;
	margin-right: 20px;
}

.projectsSection {
	text-align: center;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.scrollableProjects {
	max-height: 60vh; /* Set the maximum height for the scrollable section */
	overflow-y: auto; /* Enable vertical scrolling */
	width: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	overflow-x: hidden;
}

.projectsGrid {
	display: grid;
	grid-template-columns: 1fr 1fr; /* Two items per row */
	gap: 20px;
}

.imageGrid {
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(150px, 1fr)
	); /* Responsive grid */
	gap: 5px;
}

.projectItem {
	border-radius: 10px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
}

.projectItemImage {
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.projectItemInfo {
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
	color: #fff;
}

.projectItemInfo h3 {
	margin: 0;
	font-size: large;
	font-weight: bold;
}

.projectItemInfo span {
	font-size: medium;
	color: lightgray;
}

.closeButton {
	position: absolute;
	top: 10px;
	right: 10px;
	background: red;
	color: white;
	border: none;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 18px;
}

.back-button {
	background-color: #4caf50; /* Yeşil */
	border: none;
	color: white;
	padding: 10px 10px;
	text-align: center;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	margin: 2px 2px;
	cursor: pointer;
	border-radius: 12px;
	transition-duration: 0.4s;
}

.back-button:hover {
	background-color: white;
	color: black;
}

.info-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: #1a1a1a;
	padding: 20px;
	color: #fff;
}

.info-item {
	text-align: center;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.icon {
	margin-bottom: 10px;
	font-size: 24px; /* İkon boyutunu belirleyin */
}

.flag-icon {
	width: 32px;
	height: auto;
}

.info-text h4 {
	margin: 0;
	font-size: 12px;
	font-weight: bold;
	color: #888;
}

.info-text p {
	margin: 0;
	font-size: 14px;
	color: #fff;
}

.info-item:not(:last-child) {
	border-right: 1px solid #333;
}

.visitButton {
	background-color: #007bff;
	color: white;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
	border-radius: 5px;
	margin-top: 10px;
	transition: background-color 0.3s ease;
}

.visitButton:hover {
	background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 1024px) {
	.projectContainer {
		flex-direction: column;
		text-align: center;
	}
	.projectImageContainer {
		justify-content: center;
		margin-top: 20px;
	}
	.projectImage {
		width: 100%;
		height: auto;
		max-width: none;
	}
	.appImage {
		width: 100%;
		height: auto;
		max-width: none;
	}
	.info-container {
		flex-direction: column;
	}
	.info-item:not(:last-child) {
		border-right: none;
		border-bottom: 1px solid #333;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}
	.projectsGrid {
		grid-template-columns: 1fr; /* Single item per row */
	}
	.logoImage,
	.profileImage {
		margin: 0;
	}
	.projectInfo h3 {
		align-items: center;
		text-align: center;
		justify-content: center;
	}
	.projectTitle {
		text-align: center;
	}
}

@media (max-width: 768px) {
	.headerText {
		font-size: x-large;
	}
	.projectTitle {
		font-size: large;
		text-align: center;
	}
	.projectDescription {
		font-size: small;
	}
	.projectCategory {
		font-size: small;
	}
	.projectCategoryPointer {
		font-size: small;
	}
	.closeButton {
		width: 25px;
		height: 25px;
		font-size: 14px;
		margin-bottom: 50px;
	}
	.back-button {
		font-size: 14px;
		padding: 8px 8px;
	}
	.imageGrid {
		grid-template-columns: 1fr 1fr; /* Adjust grid for smaller screens */
	}
	.logoImage,
	.profileImage {
		margin: 0;
	}
	.projectInfo h3 {
		align-items: center;
		text-align: center;
		justify-content: center;
	}
}

@media (max-width: 480px) {
	.headerText {
		font-size: large;
	}
	.projectTitle {
		font-size: medium;
	}
	.projectDescription {
		font-size: small;
	}
	.projectCategory {
		font-size: small;
	}
	.projectCategoryPointer {
		font-size: small;
	}
	.closeButton {
		width: 20px;
		height: 20px;
		font-size: 12px;
		margin-bottom: 50px;
	}
	.back-button {
		font-size: 12px;
		padding: 6px 6px;
	}
	.imageGrid {
		grid-template-columns: 1fr; /* Single column for smallest screens */
	}
	.projectContainer {
		padding: 10px;
		margin-top: 50px;
	}
	.logoImage,
	.profileImage {
		margin: 0;
	}
	.projectInfo h3 {
		align-items: center;
		text-align: center;
		justify-content: center;
	}
}
