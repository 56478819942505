.startContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 400px; /* Reduced max-width for smaller content */
	margin: auto;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
	padding: 15px; /* Reduced padding */
	backdrop-filter: blur(10px);
	position: absolute;
	bottom: 60px;
	left: 50%;
	transform: translateX(-50%);
}

.iconRow {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-bottom: 15px; /* Reduced margin */
}

.appContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60px; /* Reduced width */
	cursor: pointer;
}

.appName {
	text-align: center;
	color: #fff;
	margin-top: 5px;
	font-size: 12px; /* Reduced font size */
}

.icon {
	width: 40px; /* Reduced icon size */
	height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
}

.projectsIcon {
	background-image: url('../assets/images/folder.png');
}

.resumeIcon {
	background-image: url('../assets/images/adobe.png');
}

.githubIcon {
	background-image: url('../assets/images/github.jpg');
}

.linkedinIcon {
	background-image: url('../assets/images/linkedin.png');
}

.calendarIcon {
	background-image: url('../assets/images/calendar.png');
}

.containerMiddle {
	width: 100%;
	padding: 0 15px;
	box-sizing: border-box;
	color: #fff;
	font-size: 12px; /* Reduced font size */
}

.middleText {
	display: flex;
	justify-content: space-between;
	padding: 5px 0; /* Reduced padding */
	border-bottom: 1px solid #444;
}

.middleTextL {
	font-size: 14px; /* Reduced font size */
	font-weight: bold;
}

.middleTextR {
	font-size: 14px; /* Reduced font size */
	text-align: right;
}
.nameText {
	font-size: 14px; /* Reduced font size */
	text-align: left;
}

.skillsListp {
	margin: 4px 0; /* Reduced margin */
	font-size: 12px; /* Reduced font size */
}

.containerProfile {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px; /* Reduced padding */
	margin-top: 15px; /* Reduced margin */
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 10px;
	justify-content: space-between;
}

.startProfile {
	background-image: url('../assets/images/profile.png');
	background-size: cover;
	background-position: center;
	width: 50px; /* Reduced size */
	height: 50px;
	border-radius: 15%;
	margin-right: 8px; /* Reduced margin */
}

.containerText {
	color: #fff;
}

.projectDescriptionn {
	margin: 0;
	font-size: 14px; /* Reduced font size */
	font-weight: bold;
	text-align: center;
}

button.toggleButton {
	margin-top: 10px;
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	background-color: #575757;
	color: white;
	cursor: pointer;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

button.toggleButton:hover {
	background-color: #363636;
}

button.toggleButton svg {
	width: 16px;
	height: 16px;
}
